<template>
  <div class="phone-number">
    <label class="phone-number__label button-small">Телефон</label>
    <div class="phone-number__input-wrapper">
      <base-select-universal
        class="phone-number__input-select"
        :value="prefix"
        :option-list="countryList"
        @change="unionPhonePrefix"
      ></base-select-universal>
      <base-input class="phone-number__input" :value="phoneNumber" type="tel" @input="unionPhone"></base-input>
    </div>
  </div>
</template>

<script lang="js">
import BaseInput from "@/components/BaseInput";
import BaseSelectUniversal from "@/components/BaseSelectUniversal";
import { ref, reactive, defineComponent } from "vue";

export default defineComponent({
  name: "BasePhoneNumber",
  components: { BaseSelectUniversal, BaseInput },
  props: {
    phoneNumber: {
      type: String,
      default: '',
    }
  },
  setup(props, { emit }) {
    const phoneNumber = ref(props.phoneNumber.slice(2, props.phoneNumber.length));
    const countryList = reactive([
      {
        id: 1,
        text: "Россия (+7)",
        icon: "Russia.svg",
        value: "+7",
      },
      {
        id: 2,
        text: "Кыргызстан (+996)",
        icon: "Kyrgyzstan.svg",
        value: "+996",
      },
    ]);
    const prefix = ref(countryList[0].value);
    const unionPhone = v => {
      phoneNumber.value = v.target.value
      emit('getPhone', `${prefix.value}${phoneNumber.value}`)
    }
    const unionPhonePrefix = v => {
      prefix.value = v.target.value
      emit('getPhone', `${prefix.value}${phoneNumber.value}`)
    }


    return {
      prefix,
      unionPhone,
      unionPhonePrefix,
      phoneNumber,
      countryList,
    };
  },
});
</script>

<style scoped lang="scss">
.phone-number {
  width: 100%;
  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  &__input-select {
    width: 50%;
  }
  &__input {
    width: 100%;
  }
  &__label {
    color: var(--color-neutral-600);
  }
}
</style>
