<template>
  <div v-if="showFlag" class="people-window">
    <div class="people-window__overlay" @click="$emit('hide')"></div>
    <div class="people-window__content">
      <div class="people-window__title h4">Карточка сотрудника</div>
      <div class="people-window__person-main-info">
        <div class="people-window__person-main-info-photo">
          <base-avatar
            :info="person"
            size="80"
            screen-size-dependency
            name-color="var(--color-neutral-800)"
            column-direction
            can-change-photo
            open-in-full-screen
            @openModal="showModal = !showModal"
          />
        </div>
        <div v-if="showModal" class="people-window__photo-change-wrapper">
          <base-input type="file" name="photo" @change="onFileChange" />
          <div class="people-window__cropper-wrapper">
            <cropper
              v-if="person.photo"
              class="flexR center cropper"
              :src="image.src === null ? PHOTO_ROUTE + person.photo : image.src"
              :default-size="defaultSize"
              @change="cropPhoto"
            />
          </div>
          <span v-if="wrongImageSize">
            Изображение слишком большое, максимальный размер изображения 10Мб
          </span>
        </div>
      </div>
      <div class="people-window__form">
        <base-input label="Ник" :value="person.nickname" @input="v => (person.nickname = v.target.value)" />
        <base-select
          label="Статус"
          :option-list="[
            { id: 'work', nickname: 'В игре' },
            { id: 'fired', nickname: 'Уволен' },
            { id: 'pause', nickname: 'На паузе' },
            { id: 'workpause', nickname: 'В игре и на паузе' },
          ]"
          :value="person.status"
          @change="v => (person.status = v.target.value)"
        />
        <base-select
          label="Должность"
          :option-list="jobList"
          :value="person.id_job"
          @change="v => (person.id_job = v.target.value)"
        />
        <base-input
          label="Доп. должность"
          :value="person.job_extra"
          @input="v => (person.job_extra = v.target.value)"
        />
        <base-divider></base-divider>
        <base-input label="Фамилия" :value="person.surname" @input="v => (person.surname = v.target.value)" />
        <base-input label="Имя" :value="person.name" @input="v => (person.name = v.target.value)" />
        <base-input label="Отчетсво" :value="person.patronymic" @input="v => (person.patronymic = v.target.value)" />
        <base-select
          label="Пол"
          :option-list="[
            { id: 'm', nickname: 'Мужской' },
            { id: 'f', nickname: 'Женский' },
          ]"
          :value="person.gender"
          @change="v => (person.gender = v.target.value)"
        />
        <base-select
          label="Курение"
          :option-list="[
            { id: 'smoke', nickname: 'Курит' },
            { id: 'agreement', nickname: 'Соглашение (премия)' },
            { id: 'none', nickname: 'Нет' },
            { id: 'other', nickname: 'Другое' },
          ]"
          :value="person.smoke_status"
        />
        <base-date-picker
          type="date"
          label="День рождения"
          :value="person.birth"
          @change="v => (selectedDateBegin = v.target.value)"
        />
        <base-date-picker
          type="date"
          label="Дата начала работы"
          :value="person.date_hire"
          @change="v => (selectedDateBegin = v.target.value)"
        />
        <base-phone-number :phone-number="person.phone" @getPhone="v => (person.phone = v)" />
        <base-input label="Телеграмм" :value="person.telegram" @input="v => (person.telegram = v.target.value)" />
        <base-select
          label="Банк"
          :option-list="[
            { id: 'Альфа', nickname: 'Альфа' },
            { id: 'Сбер', nickname: 'Сбер' },
            { id: 'Тинек', nickname: 'Тинек' },
            { id: 'Другое', nickname: 'Другое' },
          ]"
          :value="person.bank"
          @change="v => (person.bank = v.target.value)"
        />
        <base-divider></base-divider>
        <base-multiselect
          :values="person.roles"
          :options="[
            { text: 'User', value: 'user' },
            { text: 'Admin', value: 'admin' },
            { text: 'HR Admin', value: 'hradmin' },
            { text: 'Point Admin', value: 'pointadmin' },
          ]"
          @select="v => (person.roles = v)"
        />
        <base-input label="Логин" :value="person.login" @input="v => (person.login = v.target.value)" />
        <base-input label="Пароль" :value="person.pass" @input="v => (person.pass = v.target.value)" />
        <div class="people-window__action-wrapper">
          <base-button class="people-window__delete __red" is-new primary small @click="deleteUser">
            Удалить сотрудника
          </base-button>
          <base-divider class="mb-3 mt-6"></base-divider>
          <div class="people-window__actions">
            <base-button is-new tertiary small @click="$emit('hide')">Отмена</base-button>
            <base-button is-new primary small @click="onSubmit">Применить</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, toRef, watch } from "vue";
import BaseAvatar from "@/components/BaseAvatar/BaseAvatar.vue";
import BaseInput from "@/components/BaseInput/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect/BaseSelect.vue";
import { request } from "@/components-js/requestSrv";
import BaseDivider from "@/components/BaseDivider/BaseDivider.vue";
import BaseDatePicker from "@/components/BaseDatePicker/BaseDatePicker.vue";
import BasePhoneNumber from "@/components/BasePhoneNumber";
import BaseButton from "@/components/BaseButton/BaseButton.vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { PHOTO_ROUTE } from "../../config/settings";
import BaseMultiselect from "@/components/BaseMultiselect/BaseMultiselect.vue";

const props = defineProps({
  showFlag: { type: Boolean },
  personIn: { type: Object },
});
const emits = defineEmits(["hide"]);
const person = ref({});
const jobList = ref([]);
const selectedDateBegin = ref();
const showModal = ref(false);
const image = ref({
  src: null,
  type: null,
});
const croppedPhoto = ref(null);
const wrongImageSize = ref(false);
const inputFile = ref(null);
const fileInput = ref("");
const getJobList = async () => {
  let result = await request("/api/job/list", "GET");
  result = result.map(item => {
    return { id: item.id, nickname: item.job_name };
  });
  jobList.value = result;
};

const getMimeType = (file, fallback = null) => {
  const byteArray = new Uint8Array(file).subarray(0, 4);
  let header = "";
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16);
  }
  switch (header) {
    case "89504e47":
      return "image/png";
    case "47494638":
      return "image/gif";
    case "ffd8ffe0":
    case "ffd8ffe1":
    case "ffd8ffe2":
    case "ffd8ffe3":
    case "ffd8ffe8":
      return "image/jpeg";
    default:
      return fallback;
  }
};

const defaultSize = ({ imageSize, visibleArea }) => {
  return {
    width: (visibleArea || imageSize).width,
    height: (visibleArea || imageSize).height,
  };
};

const onFileChange = e => {
  const { files } = e.target;
  inputFile.value = files[0];
  if (files && files[0].size > 10485760) {
    wrongImageSize.value = true;
    inputFile.value = null;
    return;
  } else {
    wrongImageSize.value = false;
  }
  if (files && files[0]) {
    if (image.value.src) {
      URL.revokeObjectURL(image.value.src);
    }
    const blob = URL.createObjectURL(files[0]);

    const reader = new FileReader();
    reader.onload = e => {
      image.value.src = blob;
      image.value.type = getMimeType(e.target.result, files[0].type);
    };
    reader.readAsArrayBuffer(files[0]);
  }
};

const cropPhoto = ({ canvas }) => {
  if (inputFile.value) {
    canvas.toBlob(blob => {
      croppedPhoto.value = blob;
    }, "image/jpeg");

    fileInput.value = inputFile.value;

    const myFile = new File([croppedPhoto.value], "", {
      type: "application/octet-stream",
      lastModifiedDate: new Date(),
    });

    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(myFile);
    fileInput.value.files = dataTransfer.files;
  }
};

watch(toRef(props, "showFlag"), newValue => {
  if (newValue) {
    person.value = props.personIn;
    person.value.photopath = "/photo/";
  }
});

const onSubmit = async () => {
  const formData = new FormData();
  formData.append("photo", inputFile.value);
  formData.append("id", person.value.id);
  formData.append("surname", person.value.surname);
  formData.append("name", person.value.name);
  formData.append("patronymic", person.value.patronymic);
  formData.append("nickname", person.value.nickname);
  formData.append("birth", person.value.birth);
  formData.append("id_job", person.value.id_job);
  formData.append("phone", person.value.phone);
  formData.append("status", person.value.status);
  formData.append("gender", person.value.gender);
  formData.append("date_hire", person.value.date_hire);
  formData.append("bank", person.value.bank);
  formData.append("telegram", person.value.telegram);
  formData.append("login", person.value.login);
  formData.append("pass", person.value.pass);
  formData.append("roles", JSON.stringify(person.value.roles));
  formData.append(
    "pointadminlist",
    !person.value.pointadminlist || person.value.pointadminlist.length < 1 ? [""] : person.value.pointadminlist
  );
  formData.append("timezone_hour", person.value.timezone_hour);
  formData.append("timezone_min", person.value.timezone_min);
  formData.append("auto_table", person.value.auto_table ? 1 : 0);
  formData.append("smoke_status", person.value.smoke_status);
  formData.append("photoPrev", person.value.photo ? person.value.photo : "");
  formData.append("job_extra", person.value.job_extra);

  if (person.value.id) {
    await request("/api/user/update", "POST", formData, null, true);
  } else {
    const check = await request("/api/user/checkunique", "POST", {
      login: person.value.login,
    });
    if (check[0].cnt) {
      await request("/api/user/insert", "POST", formData, null, true);
    }
  }
  emits("hide");
};

const deleteUser = () => {};

onMounted(() => {
  getJobList();
});
</script>
<style lang="scss" scoped>
.cropper {
  width: 325px;
  height: 200px;
  .vue-advanced-cropper__boundaries {
    width: 325px;
    height: 200px;
  }
}
.people-window {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
    background: rgba(15, 23, 42, 0.2);
  }
  &__cropper-wrapper {
    position: relative;
  }

  &__content {
    border-radius: 4px;
    max-width: 390px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    padding: var(--sp-6) var(--sp-6) 0 var(--sp-6);
    background: var(--color-basic-white);
    border-radius: 4px;
    max-height: 800px;
    overflow-y: auto;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    .base-button-new {
      flex-grow: 1;
    }
  }

  &__form {
    gap: 24px;
  }

  &__action-wrapper {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--color-basic-white);
    padding: 0 0 12px 0;
  }

  &__delete.__red {
    background: var(--Red-Light-bg, #ffe7ef);
    color: var(--Red-Dark-main, #ad0015);
    &:hover {
      box-shadow: inset 0 0 0 2px var(--Red-Dark-main, #ad0015);
      background: var(--Red-Light-bg, #ffe7ef);
      color: var(--Red-Dark-main, #ad0015);
    }
  }
}
</style>
