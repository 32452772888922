<template>
  <div class="base-multiselect">
    <div class="base-multiselect__selected-items" @click="showOptions = !showOptions">
      <div class="base-multiselect__selected-item-wrapper">
        <div v-for="(selected, index) in selectedValues" :key="index" class="base-multiselect__selected-item">
          {{ selected }}
        </div>
      </div>
      <base-icon screen-size-dependency height="16" width="16" path="arrow-down.svg"></base-icon>
    </div>
    <div v-if="showOptions" class="base-multiselect__options">
      <div
        v-for="(option, index) in options"
        :key="index"
        class="base-multiselect__option"
        @click="selectValue(option, index)"
      >
        {{ option.text }}
        <base-icon
          v-if="activeIndexes.includes(index)"
          screen-size-dependency
          height="16"
          width="16"
          path="Check.svg"
        ></base-icon>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";

export default defineComponent({
  name: "BaseMultiselect",
  components: { BaseIcon },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const selectedValues = ref([...props.values]);
    const showOptions = ref(false);
    const activeIndexes = ref([]);
    const selectValue = (value, activeIndex) => {
      const index = selectedValues.value.indexOf(value.value);
      if (index !== -1) {
        if (selectedValues.value.length !== 1) {
          selectedValues.value.splice(index, 1);
          activeIndexes.value.splice(index, 1);
        }
      } else {
        selectedValues.value.push(value.value);
        activeIndexes.value.push(activeIndex);
      }
      emit("select", selectedValues.value);
    };

    onMounted(() => {
      props.options.forEach((item, index) => {
        if (selectedValues.value.includes(item.value)) {
          activeIndexes.value.push(index);
        }
      });
    });

    return {
      showOptions,
      activeIndexes,
      selectedValues,
      selectValue,
    };
  },
});
</script>
<style lang="scss" scoped>
.base-multiselect {
  position: relative;
  &__selected-items {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    padding: var(--sp-2) var(--sp-3);
    border-radius: 5px 5px 0 0;
    border: 2px solid var(--color-neutral-200);
  }

  &__selected-item-wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__options {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 40px;
    cursor: pointer;
    background: var(--color-basic-white);
    border-radius: 0 0 5px 5px;
    border-bottom: 2px solid var(--color-neutral-200);
    border-left: 2px solid var(--color-neutral-200);
    border-right: 2px solid var(--color-neutral-200);
  }
  &__option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--sp-2) var(--sp-3);
    &:hover {
      background-color: var(--color-neutral-200);
    }
  }
}
</style>
