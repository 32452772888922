<template>
  <div class="divider" :style="{ height: `${height}px`, background: color }"></div>
</template>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "BaseDivider",
  props: {
    height: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: "var(--color-neutral-200)",
    },
  },
});
</script>
<style lang="scss">
.divider {
  width: 100%;
}
</style>
